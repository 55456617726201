var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showTooltip)?_c('div',[_c('EXTooltip',{staticClass:"crt-div",attrs:{"title":'Click to edit checkpoints',"placement":"top","delay":300}},[_c('div',{staticClass:"checkpoint-badge-parent"},[_c('div',{staticClass:"checkpoint-badge",class:{
          'checkpoint-badge-red':
            _vm.countApprentice === 0 &&
            _vm.countProfessional === 0 &&
            _vm.countCoach === 0 &&
            _vm.countMaster === 0,
          'checkpoint-badge-gray':
            _vm.countApprentice !== 0 ||
            _vm.countProfessional !== 0 ||
            _vm.countCoach !== 0 ||
            _vm.countMaster !== 0,
        }},[_c('div',{staticClass:"main-check"},[_c('Icon',{staticClass:"checkpoint-icon",attrs:{"name":"checklist_icon","disabled":_vm.countApprentice !== 0 ||
              _vm.countProfessional !== 0 ||
              _vm.countCoach !== 0 ||
              _vm.countMaster !== 0
                ? true
                : false}}),_c('div',{staticClass:"checkpoint-apprentice",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('apprentice')}}},[_vm._v(" "+_vm._s(_vm.countApprentice)+" ")]),_c('div',{staticClass:"checkpoint-professional",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('professional')}}},[_vm._v(" "+_vm._s(_vm.countProfessional)+" ")]),_c('div',{staticClass:"checkpoint-coach",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('coach')}}},[_vm._v(" "+_vm._s(_vm.countCoach)+" ")]),_c('div',{staticClass:"checkpoint-master",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('master')}}},[_vm._v(" "+_vm._s(_vm.countMaster)+" ")])],1)])])])],1):_c('div',{staticClass:"checkpoint-badge-parent"},[_c('div',{staticClass:"checkpoint-badge",class:{
        'checkpoint-badge-red':
          _vm.countApprentice === 0 &&
          _vm.countProfessional === 0 &&
          _vm.countCoach === 0 &&
          _vm.countMaster === 0,
        'checkpoint-badge-gray':
          _vm.countApprentice !== 0 ||
          _vm.countProfessional !== 0 ||
          _vm.countCoach !== 0 ||
          _vm.countMaster !== 0,
      }},[_c('div',{staticClass:"main-check"},[_c('Icon',{staticClass:"checkpoint-icon",attrs:{"name":"checklist_icon","disabled":_vm.countApprentice !== 0 ||
            _vm.countProfessional !== 0 ||
            _vm.countCoach !== 0 ||
            _vm.countMaster !== 0
              ? true
              : false}}),_c('div',{staticClass:"checkpoint-apprentice",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('apprentice')}}},[_vm._v(" "+_vm._s(_vm.countApprentice)+" ")]),_c('div',{staticClass:"checkpoint-professional",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('professional')}}},[_vm._v(" "+_vm._s(_vm.countProfessional)+" ")]),_c('div',{staticClass:"checkpoint-coach",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('coach')}}},[_vm._v(" "+_vm._s(_vm.countCoach)+" ")]),_c('div',{staticClass:"checkpoint-master",on:{"dblclick":function($event){$event.stopPropagation();return _vm.handleDoubleClick('master')}}},[_vm._v(" "+_vm._s(_vm.countMaster)+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }