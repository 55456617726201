import { render, staticRenderFns } from "./AsMaster.vue?vue&type=template&id=85fd6df6&scoped=true"
import script from "./AsMaster.vue?vue&type=script&lang=js"
export * from "./AsMaster.vue?vue&type=script&lang=js"
import style0 from "./AsMaster.vue?vue&type=style&index=0&id=85fd6df6&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85fd6df6",
  null
  
)

export default component.exports