import { render, staticRenderFns } from "./InspectionIssueBadge.vue?vue&type=template&id=d2eb89d4&scoped=true"
import script from "./InspectionIssueBadge.vue?vue&type=script&setup=true&lang=js"
export * from "./InspectionIssueBadge.vue?vue&type=script&setup=true&lang=js"
import style0 from "./InspectionIssueBadge.vue?vue&type=style&index=0&id=d2eb89d4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2eb89d4",
  null
  
)

export default component.exports