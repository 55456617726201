import { render, staticRenderFns } from "./AsEmployee.vue?vue&type=template&id=d6901cee&scoped=true"
import script from "./AsEmployee.vue?vue&type=script&lang=js"
export * from "./AsEmployee.vue?vue&type=script&lang=js"
import style0 from "./AsEmployee.vue?vue&type=style&index=0&id=d6901cee&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6901cee",
  null
  
)

export default component.exports