<template>
  <v-container fluid>
    <PageTitle title="Employee Path to Readiness" style="margin-bottom: 10px" />

    <PathToReadinessBar
      :readiness="employeeData?.current_readiness"
      :needsRecalc="employeeData?.needs_recalc"
      @onRecalc="onEmployeeReadiness()"
    />
    <div class="custom-path">
      <PathToReadinessTreeList
        :dataSource="dataSource"
        :topLevel="topLevel"
        :removeBorder="removeBorder"
        :dropdownItems="dropdownItems"
        @optionClick="onOptionClick"
        :showRespBadge="true"
        @loadData="loadData()"
      />
    </div>
    <EmployeeModal
      :dialog="isForceBadge"
      label="Responsibility"
      :name="currentResponsibility?.name"
      :empForceBadgeClass="true"
      :hideEmployeePicker="true"
      :value="[1]"
      @back="isForceBadge = false"
      @onAction="onClickForceBadge"
    />
    <EvalResponsibilityModal
      v-if="isInspectResModal"
      :dialog="isInspectResModal"
      :badges="badges"
      :checkpointsData="checkpointsData"
      :evalModalHeaderState="evalModalHeaderState"
      :evalModalEmpState="evalModalEmpState"
      @closeModal="isInspectResModal = false"
      :currentResponsibilityId="currentResponsibility?.id"
      :assignmentData="false"
      @grantBadge="grantOrForceBadge"
      @reloadData="reloadCheckpointsData"
    />
  </v-container>
</template>
<script>
import PathToReadinessTreeList from "@components/DataTable/PathToReadinessTreeList.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import PathToReadinessBar from "@components/PathToReadinessBar/PathToReadinessBar.vue";
import EmployeeModal from "@components/EmployeeModal/EmployeeModal.vue";
import EvalResponsibilityModal from "@components/EvalResponsibilityModal/EvalResponsibilityModal.vue";
import {
  getResponsibilityChecklist,
  getListActiveCheckpointsEval,
  createProficiencyBadge,
  createResponsibilityBadge,
  // getAssignmentEval,
} from "@/api";
import { getListofResponsibility } from "@/api";
import {
  getEmployeeAssignment,
  getEmployeeById,
  recalculateEmployeeReadiness,
} from "@/api";
import PageTitle from "@components/common/PageTitle.vue";

export default {
  name: "EmployeePathToReadinessView",
  components: {
    PathToReadinessTreeList,
    PathToReadinessBar,
    PageTitle,
    EmployeeModal,
    EvalResponsibilityModal,
  },
  data() {
    return {
      dataSource: [],
      topLevel: "position",
      removeBorder: false,
      currentReadiness: null,
      currentSelectRes: null,
      currentResponsibility: null,
      isForceBadge: false,
      showEmployeeIssueBadge: false,
      isInspectResModal: false,
      allResponsibilities: [],
      checkpointsData: [],
      evalModalEmpState: null,
      evalModalHeaderState: null,
      responsibilitiesToUseIds: [],
      badges: [
        {
          badge_id: "Apprentice",
          type: "Forced",
        },
        {
          badge_id: "Professional",
          is_force: true,
        },
        {
          badge_id: "Coach",
        },
        {
          badge_id: "Master",
          is_force: false,
        },
      ],
      employeeData: null,
      cachedAccountID: null,
      cachedEmpId: null,
      cachedAuthUser: null,
    };
  },
  mixins: [dropdownMixin],
  async mounted() {
    this.cachedAccountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.cachedAuthUser = JSON.parse(localStorage.getItem("user"));
    this.cachedEmpId = this.$route?.params?.id;

    await Promise.all([this.loadData(), this.onEmployeeReadiness()]);
  },
  methods: {
    dropdownItems(data) {
      const items = [];
      if (data?.data?.type === "responsibility" && !data?.data?.badge) {
        items.push({
          name: "Issue Badge",
          value: "issue_badge_action",
          icon: "plainminimalistic.svg",
          order: 2,
        });
      }
      return items;
    },
    async reloadCheckpointsData(data) {
      const accountID =
        this.cachedAccountID ||
        JSON.parse(localStorage.getItem("currentAccountID"));
      const data3 = await getListActiveCheckpointsEval(
        accountID,
        data.responsibility,
        data.employee
      );
      await this.formatChecklistData(data3);
    },
    async grantOrForceBadge(badgeData) {
      const accountID =
        this.cachedAccountID ||
        JSON.parse(localStorage.getItem("currentAccountID"));
      await createProficiencyBadge(accountID, badgeData.payload);
      this.isInspectResModal = false;
      await this.loadData();
      await this.onEmployeeReadiness();
    },
    async loadData() {
      const empId = this.cachedEmpId || this.$route?.params?.id;
      const authUser =
        this.cachedAuthUser || JSON.parse(localStorage.getItem("user"));
      this.$loader.show();

      try {
        await Promise.all([
          this.getAuthUser(empId),
          this.getEmpAssignments(authUser?.id, empId),
          this.getAllResponsibilities(),
        ]);

        const responsibilitiesIdsNew = [];
        const uniqueRespIds = [...new Set(this.responsibilitiesToUseIds)]; // Eliminar duplicados

        uniqueRespIds.forEach((respId) => {
          const respObj = this.allResponsibilities.find(
            (el) => el.id == respId
          );
          if (respObj) {
            responsibilitiesIdsNew.push({
              id: respObj.id,
              code: respObj.code_str,
            });
          }
        });

        await this.loadAllResponsibilitiesCheckpointsData(
          responsibilitiesIdsNew
        );
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.$loader.hide();
      }
    },
    async loadAllResponsibilitiesCheckpointsData(responsibilitiesIds) {
      const empId = this.cachedEmpId || this.$route?.params?.id;
      const accountID =
        this.cachedAccountID ||
        JSON.parse(localStorage.getItem("currentAccountID"));

      const checkpointsPromises = responsibilitiesIds.map(async (resp) => {
        try {
          const checkpointsData = await this.loadAllCheckpointsEvaluationData(
            accountID,
            empId,
            resp.id
          );

          if (checkpointsData && checkpointsData.length > 0) {
            const mappedData = checkpointsData.map((el) => ({
              id: el.id,
              evaluation_status: el.evaluation_status,
              proficiency_level: el.proficiency_level,
              status: el.status,
              responsibility: el.responsibility,
            }));

            const indexResponsibility = this.dataSource.findIndex(
              (el) => el.data.code == resp.code
            );

            if (indexResponsibility > -1) {
              const newElement = {
                ...this.dataSource[indexResponsibility],
                checkpointsData: mappedData,
              };
              this.dataSource.splice(indexResponsibility, 1, newElement);
            }
          }
        } catch (error) {
          console.error(
            `Error loading checkpoint data for responsibility ${resp.id}:`,
            error
          );
        }
      });

      await Promise.all(checkpointsPromises);
    },
    async loadAllCheckpointsEvaluationData(
      accountId,
      employeeId,
      responsibilityId
    ) {
      const response = await getListActiveCheckpointsEval(
        accountId,
        responsibilityId,
        employeeId
      );
      return response.data;
    },
    async getEmpAssignments(userId, empId) {
      const val = true;
      const { data } = await getEmployeeAssignment(userId, empId, val);
      if (data.length > 0) {
        this.dataSource = this.transformData(data);
      }
    },
    async getAuthUser(empId) {
      try {
        const accountID =
          this.cachedAccountID ||
          JSON.parse(localStorage.getItem("currentAccountID"));
        const { data } = await getEmployeeById(accountID, empId);
        if (data) {
          this.$store.dispatch("employeeStore/setCurrentEmployeeAction", data);
          this.employeeData = data;
          this.currentReadiness = { current_readiness: data.current_readiness };
        }
      } catch (error) {
        console.error("Error getting user:", error);
      }
    },
    async onEmployeeReadiness() {
      const empId = this.cachedEmpId || this.$route?.params?.id;
      const accountID =
        this.cachedAccountID ||
        JSON.parse(localStorage.getItem("currentAccountID"));
      const authUser =
        this.cachedAuthUser || JSON.parse(localStorage.getItem("user"));
      const val = true;
      try {
        const [readinessResponse] = await Promise.all([
          recalculateEmployeeReadiness(accountID, empId, val),
        ]);

        this.employeeData = readinessResponse.data;

        await Promise.all([
          this.getAuthUser(empId),
          this.getEmpAssignments(authUser?.id, empId),
        ]);
      } catch (error) {
        console.error("Error in onEmployeeReadiness:", error);
      }
    },
    async onEmployeeDetails() {
      const empId = this.$route?.params?.id;
      await this.getAuthUser(empId);
    },

    transformData(dataArray) {
      const result = [];
      const today = new Date();

      const existingRespMap = {};
      this.dataSource.forEach((item) => {
        if (
          item.data &&
          item.data.code &&
          item.data.type === "responsibility" &&
          item.checkpointsData
        ) {
          existingRespMap[item.data.code] = item;
        }
      });

      dataArray.forEach((data) => {
        const isNew =
          new Date(data.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
          today.getTime();

        const mainObject = {
          id: data.id, // Use API ID
          parentId: -1, // Root-level has no parent
          data: {
            type: data.assignment_type,
            commitmentLevel: data.commitment_level,
            assignmentType: data.assignment_type,
            importanceLevel: data.importance,
            importanceSelector: true,
            totalSteps: 3,
            code: data.position_code,
            name: data.position_name,
            isNew: isNew,
            isAccepted: false,
            acceptanceStatus: "pending",
          },
          assignment_id: data.id,
          status: data.status,
          readiness:
            data.current_readiness === 0
              ? this.employeeData?.current_readiness
              : data.current_readiness,
        };
        result.push(mainObject);

        if (data.role_assignments) {
          data.role_assignments.forEach((role) => {
            const isRoleNew =
              new Date(role.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
              today.getTime();

            const roleObject = {
              id: `role-${role.id}`, // Ensure uniqueness with a prefix
              parentId: data.id, // Set parent ID to main object
              data: {
                type: role.assignment_type,
                commitmentLevel: role.commitment_level,
                assignmentType: role.assignment_type,
                importanceLevel: role.importance,
                importanceSelector: true,
                totalSteps: 3,
                code: role.role_code,
                name: role.role_name,
                isNew: isRoleNew,
                isAccepted: isRoleNew,
                acceptanceStatus: "pending",
                assignment_id: data.id,
              },
              readiness: role.current_readiness,
            };
            result.push(roleObject);

            if (role.responsibility_assignments) {
              role.responsibility_assignments.forEach((resp) => {
                this.responsibilitiesToUseIds.push(resp.responsibility_id);
                const isRespNew =
                  new Date(resp.assigned_on).getTime() +
                    10 * 24 * 60 * 60 * 1000 >
                  today.getTime();
                const responsibilityObject = {
                  id: `resp-${resp.id}`, // Ensure uniqueness
                  parentId: `role-${role.id}`, // Correctly set parent ID
                  data: {
                    type: resp.assignment_type,
                    commitmentLevel: resp.commitment_level,
                    assignmentType: resp.assignment_type,
                    importanceLevel: resp.importance,
                    importanceSelector: true,
                    totalSteps: 3,
                    code: resp.responsibility_code,
                    name: resp.responsibility_name,
                    isNew: isRespNew,
                    isAccepted: isRespNew,
                    acceptanceStatus: "pending",
                    assignment_id: data.id,
                    badge: resp.badge,
                  },
                  status: resp.status,
                  readiness: resp.current_readiness,
                };
                const existingResp = existingRespMap[resp.responsibility_code];
                if (existingResp && existingResp.checkpointsData) {
                  responsibilityObject.checkpointsData =
                    existingResp.checkpointsData;
                }

                result.push(responsibilityObject);
              });
            }
          });
        }
      });

      return result;
    },

    async getAllResponsibilities() {
      try {
        const accountID =
          this.cachedAccountID || localStorage.getItem("currentAccountID");
        const data = await getListofResponsibility(accountID);
        this.allResponsibilities = data.data.results;
      } catch (error) {
        console.error("Error fetching responsibilities:", error);
      }
    },
    onOptionClick(item) {
      this.onIssueBadge(item);
    },
    async onIssueBadge(item) {
      let currentEmployee = null;
      this.currentSelectRes = item;
      this.currentResponsibility = this.allResponsibilities.find(
        (responsibility) => responsibility.code_str === item.data.data.code
      );
      const account_id =
        this.cachedAccountID ||
        JSON.parse(localStorage.getItem("currentAccountID"));
      const emp_id = Number(localStorage.getItem("emp_id")); // Convert to number if needed
      const res_id = this.currentResponsibility.id;

      const data = await getResponsibilityChecklist(account_id, res_id);
      const hasActiveStatus = data.data.results.some(
        (item) => item.status === "active"
      );
      if (hasActiveStatus) {
        const data3 = await getListActiveCheckpointsEval(
          account_id,
          res_id,
          emp_id
        );
        await this.formatChecklistData(data3);
        this.showEmployeeIssueBadge = false;
        this.isForceBadge = false;
        this.isInspectResModal = true;
        this.evalModalHeaderState = {
          responsibilityCode: this.currentResponsibility.code,
          responsibilityName: this.currentResponsibility.name,
          roleCode: "#245",
          roleName: "Implement CAPEX initiatives",
          positionCode: "#4567",
          positionName: "General Manager",
        };
        const exlAppData = JSON.parse(localStorage.getItem("exlAppData"));
        const employees = exlAppData?.employees; // Add optional chaining to avoid errors

        if (Array.isArray(employees)) {
          currentEmployee = employees.find((e) => e.id === emp_id); // Assign found employee
        } else {
          console.error("Employees data is not an array.");
        }
        const badgeList = this.getBadgesFromDataSource(this.dataSource);
        if (currentEmployee) {
          this.evalModalEmpState = {
            id: currentEmployee.id,
            firstName: currentEmployee.first_name,
            lastName: currentEmployee.last_name,
            position: currentEmployee.title,
            photoUrl: currentEmployee.avatar,
            availability: 1,
            isAllocated: false,
            badgeList,
          };
        } else {
          console.error("No employee found with ID:", emp_id);
        }
      } else {
        this.showEmployeeIssueBadge = false;
        this.isInspectResModal = false;
        this.isForceBadge = true;
      }
      this.$loader.setAppLoading(false);
    },
    formatChecklistData(data) {
      if (data && data.data) {
        this.checkpointsData = data.data.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.status = el.id;
          newEl.level = el.proficiency_level;
          newEl.level_name = this.getProficiencyLevelNameFromNumber(el);
          newEl.criteria = el.criteria;
          newEl.instructions = el.instructions;
          newEl.status = el.status;
          newEl.evaluation_status = el.evaluation_status;
          newEl.change_evaluation_status =
            this.updateChangeEvaluationStatus(el);
          newEl.failed_by = el.failed_by;
          newEl.failed_on = el.failed_on;
          newEl.failed_reason = el.evaluation_reason;
          newEl.reset_by = el.reset_by;
          newEl.reset_on = el.reset_on;
          newEl.reset_reason = el.reset_reason;
          newEl.avatar =
            "https://www.shareicon.net/data/512x512/2016/09/15/829472_man_512x512.png";
          return newEl;
        });
      }
      return this.checkpointsData;
    },
    getProficiencyLevelNameFromNumber(el) {
      let levelName = "";
      if (el.proficiency_level === 1) {
        levelName = "Apprentice";
      } else if (el.proficiency_level === 2) {
        levelName = "Professional";
      } else if (el.proficiency_level === 3) {
        levelName = "Coach";
      } else {
        levelName = "Master";
      }
      return levelName;
    },
    updateChangeEvaluationStatus(data) {
      if (data.evaluation_status == "not_applicable") {
        return "Not Applicable";
      } else if (data.evaluation_status == "passed") {
        return "Passed";
      } else if (data.evaluation_status == "failed") {
        return "Failed";
      } else if (data.evaluation_status == "reset") {
        return "Reset Pending Evaluation";
      } else {
        return "Pending Evaluation";
      }
    },
    async onClickForceBadge(item) {
      try {
        this.$loader.setAppLoading(true);
        const resId = this.currentResponsibility?.id;
        const emp_id = localStorage.getItem("emp_id");
        const accountID =
          this.cachedAccountID || localStorage.getItem("currentAccountID");
        const payload = {
          level: item.radioVal,
          issued_to: emp_id,
          remove_reason: item.reason,
          issued_for: resId,
        };
        await createResponsibilityBadge(accountID, resId, payload);
        await this.loadData();
        this.$loader.setAppLoading(false);
        this.isForceBadge = false;
        this.showEmployeeIssueBadge = false;
        this.isForceBadge = false;
        this.currentSelectRes = null;
        this.currentResponsibility = null;
      } catch (error) {
        this.isForceBadge = false;
        this.showEmployeeIssueBadge = false;
        this.isForceBadge = false;
        this.currentSelectRes = null;
        this.$loader.setAppLoading(false);
      }
    },
    getBadgesFromDataSource(dataSource) {
      const badgeList = [];
      dataSource.forEach((item) => {
        if (item.data && item.data.badge) {
          const badge = item.data.badge;
          const newBadge = {
            id: badge.id,
            issued_to: badge.issued_to,
            is_current: badge.is_current,
          };
          badgeList.push(newBadge);
        }
      });
      return badgeList;
    },
  },
};
</script>
<style>
.custom-path-readiness {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.readiness-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #344054;
}
.custom-readiness {
  width: 100%;
  margin-left: 15px;
}
.custom-readiness .progress-bar-container {
  width: 100%;
}
.custom-readiness .progress-value {
  font-weight: 600;
}
.custom-path {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
}
</style>
