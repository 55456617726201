<template>
  <v-container fluid>
    <UniversalTable
      :config="tableConfig.tableParams.value"
      :items="filteredMasteredResponsibilities"
      @onSearch="viewState.state.filters.searchText = $event"
      @onClick="viewState.state.filters.showFilterDialog = true"
      @removeFilter="viewState.removeFilters"
      @removeAllFilters="viewState.state.filters.values = []"
      :emptyObjectData="emptyObjectData"
    >
    </UniversalTable>
    <SearchModal
      :dialog="viewState.state.filters.showFilterDialog"
      @back="viewState.state.filters.showFilterDialog = false"
      @checkedItemsChanged="viewState.applyFilters"
      :clearSelection="viewState.state.filters.clearSelection"
      filterType="Employee Readiness"
      :filterGroups="viewState.state.filters.filterGroups"
      :statusOptions="[]"
      :modalWidth="800"
      :defaultSelected="viewState.state.filters.values"
    />
  </v-container>
</template>

<script setup>
import { computed } from "vue";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import { useTableConfig } from "@/views/AsMaster/composables/useTableConfig";
import { useViewState } from "@/views/AsMaster/composables/useViewState";
import SearchModal from "@components/SearchBar/SearchModal.vue";

const tableConfig = useTableConfig();
const viewState = useViewState();
const emptyObjectData = {
  singular: "Mastered Responsibility",
  plural: "Mastered Responsibilities",
};
const dataStream = [
  {
    id: 1,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: false,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 2,
    name: "Responsibility name",
    code: "#9998",
    isCoach: false,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 3,
    name: "Responsibility name",
    code: "#9999",
    isCoach: false,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 4,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: false,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 5,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 6,
    name: "Responsibility name",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 7,
    name: "Responsibility name",
    code: "#9999",
    isCoach: false,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 8,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 9,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 10,
    name: "Responsibility name",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 11,
    name: "Responsibility name",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 12,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 13,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 14,
    name: "Responsibility name",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 15,
    name: "Responsibility name",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
  {
    id: 16,
    name: "Test Responsibility",
    code: "#9999",
    isCoach: true,
    checkpoint: {
      apprentice: 3,
      professional: 2,
      master: 4,
      coach: 3,
    },
  },
];

const filteredMasteredResponsibilities = computed(() => {
  let allMasteredResponsibilities = dataStream;

  if (
    viewState.state.filters.searchText &&
    viewState.state.filters.searchText.length > 0
  ) {
    const searchText = viewState.state.filters.searchText.toLowerCase();

    allMasteredResponsibilities = allMasteredResponsibilities.filter((el) => {
      const fullName = `${el.name} ${el.code}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        el.name.toLowerCase().includes(searchText) ||
        el.code.toLowerCase().includes(searchText)
      );
    });
  }

  allMasteredResponsibilities = allMasteredResponsibilities.sort((a, b) => {
    const firstNameA = a?.name?.toLowerCase() || "";
    const firstNameB = b?.name?.toLowerCase() || "";
    const lastNameA = a?.code?.toLowerCase() || "";
    const lastNameB = b?.code?.toLowerCase() || "";

    if (firstNameA < firstNameB) return -1;
    if (firstNameA > firstNameB) return 1;

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;

    return 0;
  });
  return allMasteredResponsibilities;
});
</script>

<style scoped>
.icon-container {
  cursor: pointer;
}
</style>
