<template>
  <div class="issue-badge-container">
    <InspectionIssueBadgeLevelBox
      v-if="currentBadgeLevel == 'novice'"
      :badgeLevel="currentBadgeLevel"
    />
    <div
      class="d-flex issue-badge-container-item"
      v-for="item in localCheckpointsData"
      :key="item.proficiency_level"
    >
      <InspectionIssueBadgeCheckpointBox
          :type="item.label"
          :checkpointsValue="item.checkpoints"
      />
      <InspectionIssueBadgeBar
          v-if="item.label == readyFor"
          :readyFor="readyFor"
      />
      <InspectionIssueBadgeLevelBox
        v-if="item.isCurrentLevel"
        :badgeLevel="item.label"
        :badgeStatus="currentBadgeStatus"
      />
    </div>
  </div>
</template>
<script setup>
import InspectionIssueBadgeCheckpointBox from "@components/InspectionIssueBadge/InspectionIssueBadgeCheckpointBox.vue";
import InspectionIssueBadgeLevelBox from "@components/InspectionIssueBadge/InspectionIssueBadgeLevelBox.vue";
import InspectionIssueBadgeBar from "@components/InspectionIssueBadge/InspectionIssueBadgeBar.vue";
import { computed } from "vue";
const props = defineProps({
  currentBadgeLevel: {
    type: String,
    required: false,
    validator: (value) => ['novice', 'apprentice', 'professional', 'coach', 'master'].includes(value)
  },
  currentBadgeStatus: {
    type: String,
    required: false,
    validator: (value) => ['forced', 'good_standing', 'obsolete'].includes(value)
  },
  readyFor: {
    type: String,
    required: false,
    validator: (value) => [null, 'apprentice', 'professional', 'coach', 'master'].includes(value)
  },
  checkpoints: {
    type: Array,
    required: true
  },
})

const localCheckpointsData = computed(() => {
  if (!props || !props?.checkpoints) return [];

  const groupedByLevel = {};

  props.checkpoints.forEach(checkpoint => {
    const level = checkpoint.proficiency_level;

    if (!groupedByLevel[level]) {
      groupedByLevel[level] = [];
    }

    groupedByLevel[level].push(checkpoint);
  });

  const result = [];

  for (let level = 1; level <= 4; level++) {
    const checkpointsInLevel = groupedByLevel[level] || [];

    const notPassedCount = checkpointsInLevel.filter(
        checkpoint => checkpoint.evaluation_status !== "passed"
    ).length;

    result.push({
      proficiency_level: level,
      label: getLabelFromProficiencyLevel(level),
      count: checkpointsInLevel.length,
      checkpoints: checkpointsInLevel.length > 0 ? notPassedCount : null,
      isCurrentLevel: props.currentBadgeLevel == getLabelFromProficiencyLevel(level),
    });
  }

  return result;
})

const readyFor = computed(() => {
  if (props.readyFor) return props.readyFor;
  if (!localCheckpointsData) return null
  let readyForLevel = 0;
  let block = false;
  localCheckpointsData.value.forEach((checkpoint) => {
    if (!block && checkpoint.checkpoints == 0) {
      readyForLevel++;
    } else {
      block = true;
    }
  })
  if (readyForLevel > 0) {
    return getLabelFromProficiencyLevel(readyForLevel);
  }
  return null;
})

const getLabelFromProficiencyLevel = (level) => {
  switch (level) {
    case 1:
      return 'apprentice';
    case 2:
      return 'professional';
    case 3:
      return 'coach';
    case 4:
      return 'master';
    default:
      return 'unknown';
  }
}
</script>
<style scoped>
.issue-badge-container {
  display: inline-flex;
  padding: 4px;
  background-color: #F9FAFB;
  border-radius: 4px;
  border: 1px solid #EAECF0;
  gap: 4px;
}
.issue-badge-container-item {
  gap: 4px;
}
</style>