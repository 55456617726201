<template>
  <v-container fluid class="d-flex justify-center py-0 px-0">
    <div class="py-0 pr-0 ex-login">
      <div class="login-page user-login-form d-flex justify-space-between">
        <div class="login-part flex-grow-1 px-12">
          <LoginForm
            :logintype="'account'"
            @login="login($event)"
            :alert="alert"
            :accountlist="accountlist"
            :is-loading.sync="isLoading"
            :displayLogin="displayLogin"
            @onSelectAccount="onSelectAccount($event)"
            @closeError="alert = {}"
            @logout="logout"
            :isUserClosedOrSuspended="isUserExist"
            :message="showMessage"
            :alertType="status"
            @backToLogin="onBackToLogin"
          />
        </div>
        <div
          class="login-right flex-shrink-1 d-lg-flex justify-end"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <img
            :src="require('@/assets/login/Section1.png')"
            style="height: 100vh; max-width: 100%; object-fit: contain"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import LoginForm from "@components/login/LoginForm.vue";
import { loginEmailPW, getListofAccount, getAppAccess } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("isPosition");

export default {
  name: "LoginView",

  components: {
    LoginForm,
  },

  data() {
    return {
      alert: {
        type: "",
        message: "",
      },
      accountlist: [],
      displayLogin: true,
      isLoading: false,
      isUserExist: false,
      showMessage: "",
      status: "",
    };
  },
  computed: {
    ...mapState(["positions", "activePositionsCount"]),
  },
  methods: {
    async login(event) {
      this.isLoading = true;
      const normalizedEmail = event.email;
      event.email = normalizedEmail;
      loginEmailPW(event)
        .then((result) => {
          const user = result.data.user;
          if (
            result.data.user.state === "closed" ||
            result.data.user.state === "blocked"
          ) {
            this.isUserExist = true;
            this.showMessage = `The user with email ${user.email} is closed/blocked at the level of the system.`;
            this.status = "error";
            return;
          }
          localStorage.clear();
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("user", JSON.stringify(user));

          this.$root.$emit("userLoggedIn");

          getListofAccount(result.data.user.id)
            .then(async (res) => {
              this.accountlist = res.data.results;
              if (res.data.results[0].status === "blocked") {
                this.isUserExist = true;
                this.showMessage = `Account is blocked, please contact EXiQtive support.`;
                this.status = "error";
                return;
              } else if (res.data.results[0].status === "closed") {
                this.isUserExist = true;
                this.showMessage = `Account is closed, please contact EXiQtive support.`;
                this.status = "error";
                return;
              } else if (res.data.results[0].status === "suspended") {
                this.isUserExist = true;
                this.showMessage = `Account is suspended, please contact EXiQtive support.`;
                this.status = "error";
                return;
              }
              if (res.data.results.length === 0) {
                localStorage.clear();
                this.alert = {
                  type: "error",
                  message: "You have no accounts permissions",
                };
              } else if (res.data.results.length === 1) {
                const account = res.data.results[0];
                await this.onSelectAccount(account);
              } else {
                this.accountlist.sort((a, b) => {
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                });
                this.displayLogin = false;
              }
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        })
        .catch((error) => {
          if (error.response?.data.error) {
            this.alert = {
              type: "error",
              message: error.response.data.error,
            };
          } else {
            this.alert = {
              type: "error",
              message: "Please try again, something went wrong.",
            };
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async onSelectAccount(account) {
      try {
        if (account.status === "blocked") {
          this.isUserExist = true;
          this.showMessage = `Account is blocked, please contact EXiQtive support.`;
          this.status = "error";
          return;
        } else if (account.status === "closed") {
          this.isUserExist = true;
          this.showMessage = `Account is closed, please contact EXiQtive support.`;
          this.status = "error";
          return;
        } else if (account.status === "suspended") {
          this.isUserExist = true;
          this.showMessage = `Account is suspended, please contact EXiQtive support.`;
          this.status = "error";
          return;
        }
        if (!account || !account.id) throw new Error("no account");
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) throw new Error("no user");
        localStorage.setItem("currentAccountID", account.id);
        const response = await getAppAccess(account.id, user.id);
        localStorage.setItem("accessPermission", JSON.stringify(response.data));
        const currentAccount = JSON.parse(
          localStorage.getItem("accessPermission")
        );
        if (!currentAccount?.has_employee) {
          await this.$router.push({ name: "Employees" });
        } else if (currentAccount?.has_employee) {
          await this.$router.push({ name: "AsEmployee" });
        } else {
          await this.$router.push({ name: "User" });
        }
      } catch (e) {
        console.error("error on login: ", e);
        this.$router.go(0);
      }
    },
    logout() {
      localStorage.clear();
      this.accountlist = [];
      this.displayLogin = true;
    },
    onBackToLogin() {
      this.isUserExist = false;
    },
  },
};
</script>

<style scoped>
.user-login-form .login-bottom .ex-checkbox .icon {
  margin-top: 10px !important;
}
.user-login-form .login-bottom .ex-checkbox .label {
  margin-left: 21px !important;
}
.ex-login {
  width: 100%;
  max-width: 1600px;
}
.login-page {
  height: 100%;
  width: 100%;
  background: #ffffff;
  margin: 0 !important;
}
.login-part {
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.welcome-title {
  font-style: normal;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  text-align: center;
  color: #475467;
}
.welcome-subtitle {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  font-weight: 700;
  text-align: center;
}
.login-right {
  padding: 0;
}
.login-custom-list {
  padding: 8px;
}
.login-picker
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  .theme--light.v-label {
  padding-left: 0px !important;
}
</style>
