<template>
  <v-container fluid class="px-6">
    <h1>Settings</h1>
    <SettingsMenu
      :items="settingOptions"
      :value="selectedTab"
      class="ex-menu-div"
      data-test="setting-menu"
      @onChange="handleTabChange"
    >
    </SettingsMenu>
    <v-tabs-items v-model="selectedTab" class="custom-tabs custom-tabs-shadow">
      <v-tab-item
        :transition="false"
        v-for="(item, index) in settingOptions"
        :key="index"
      >
        <v-card
          :elevation="isFlatCard ? 0 : 1"
          :class="!isFlatCard ? 'elevated-card' : ''"
        >
          <v-card-text class="custom-view" v-if="selectedTab === 0">
            <ExCreateEmployeeWizard
              :accessLevel="accessLevel"
              @created="onCreatedUser"
              @onSearch="onFilter"
              @onModalFilter="filteredModalUsers"
              @onFilterRemove="removeFilter"
              :clearSelection="clearSelection"
              :appliedFilters="appliedFilters"
              :valueSearch="filterText"
              :isDialogVisible="isAddDialogVisible"
              @closeAddDialog="isAddDialogVisible = false"
            />
            <DataTable
              class="user-tr mt-5"
              :pagination="paginationModel"
              :headers="headers"
              :data="filteredUsers"
              @onRequest="requestData"
            >
              <template #no-data> </template>
              <template #user="{ row }">
                <UserCard
                  :name="`${row.user.data.user.first_name} ${row.user.data.user.last_name}`"
                  :email="`${row.user.data.user.email}`"
                  :userStatus="`${row.user.data.user.state_str}`"
                  :user="{
                    name:
                      row.user.data.user.first_name +
                      ' ' +
                      row.user.data.user.last_name,
                    email: row.user.data.user.email,
                    avatar: '',
                    linkedToEmployee: false,
                    lostAccount:
                      row.user.data.user.state == 'suspended' ||
                      row.user.data.user.state == 'disabled',
                    employeeStatus: row.user.data.user.state,
                    userStatus: row.user.data.user.state_str,
                  }"
                ></UserCard>
              </template>
              <template #access="{ row }">
                <div style="display: flex; gap: 10px">
                  <UserPermissionBadge
                    v-for="chipData in row.user.data.accessChip"
                    :key="chipData.appDropdown"
                    :permissionType="chipData.permissionType"
                    :appDropdown="chipData.appDropdown"
                    @onClick="onRemoveAppAccess(chipData.appDropdown, row)"
                  />
                </div>
              </template>
              <template #lastlogin="{ row }">
                <span>
                  {{ getUserStateLabel(row.user.data.user.state) }}
                </span>
                <span>
                  {{ formatDate(row.user.data.user.date_of_invite) }}
                </span>
              </template>
              <template #status="{ row }">
                <div>
                  <UserStatusBadge
                    :access="row.user.data.user.state"
                    :status="
                      getUserAccess(
                        row.user.data.user.state,
                        row.user.data.is_suspended
                      )
                    "
                  />
                </div>
              </template>
              <template #actions="{ row }">
                <div>
                  <DropDownMenu
                    @onClick="handleDropdownClick($event, row)"
                    :is-open="false"
                    :dropdown-items="
                      getSettingsUsersItems(row.user.data, appUser)
                    "
                    :translateXOffset="12"
                    :translateYOffset="28"
                  >
                    <template #activator>
                      <div class="icon-container">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-svg"
                        >
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </template>
                  </DropDownMenu>
                </div>
              </template>
            </DataTable>
            <NoRecordFound
              v-if="filteredUsers.length === 0 && userList?.length != 0"
              :title="title"
              :message="title"
              :btnTitle="'User'"
              :subtitle="''"
              class="no-record-found"
              :shouldShowAddAction="true"
              @onAction="isAddDialogVisible = true"
              :showClearAction="userList?.length > 0"
              :clearLabel="clearBtnLabel"
              @onClear="clearSearchFilter()"
              :description="emptyRecordDescription"
              :noBorder="true"
            />
          </v-card-text>
          <v-card-text v-if="selectedTab === 2">
            <ConfigurationView @update-shadow="onUpdateShadow" />
          </v-card-text>
          <v-card-text v-if="selectedTab === 3">
            <AppIntegrationsList />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogTitle" persistent max-width="480px">
      <div class="pop-up">
        <div class="modal_header">
          <div class="d-flex flex-row">
            <h3 class="text-black-600 mt-5 ml-4">Change Password</h3>
            <CloseIcon className="ml-auto ma-4" @click="reset" />
          </div>
        </div>
        <div class="modal_content mt-4 px-6">
          <v-form ref="password">
            <p class="text-black-500 mb-2">Password</p>
            <v-text-field
              class="input mb-4"
              required
              placeholder="Enter new password"
              outlined
              color="rgba(208, 213, 221, 1)"
              base-color="rgba(208, 213, 221, 1)"
              name="email"
              v-model="password"
              id="password"
              type="password"
              :hide-details="true"
              :rules="validatePassword"
              autofocus
            ></v-text-field>
          </v-form>
        </div>
        <div class="modal_action d-flex justify-end px-6 py-6">
          <MainButton
            className="ml-2"
            :disabled="!canNext"
            @click="onChangePassword()"
            data-test-name="save"
          >
            <span>Save</span>
          </MainButton>
        </div>
      </div>
    </v-dialog>
    <ExActionsPopUp
      v-if="userAccountActionDialogObject?.show ?? false"
      :headline="userAccountActionDialogObject?.title"
      :htmlInfo="userAccountActionDialogObject.description"
      :actionLabel="userAccountActionDialogObject?.actionText"
      :value="userAccountActionDialogObject?.show ?? false"
      :primary="userAccountActionDialogObject.primary ?? false"
      :termination="userAccountActionDialogObject.termination ?? false"
      :destructive="userAccountActionDialogObject.destructive ?? false"
      :avatar="{
        picture: '',
        firstName: currentSelectedRow?.user?.first_name || '',
        lastName: currentSelectedRow?.user?.last_name || '',
      }"
      @onAction="OnActionUserConfirmationDialog"
      @input="OnCloseUserConfirmationDialog"
    />

    <ChangeEmailModel
      v-if="selectedTab === 0 && showChangeEmailDialog"
      :showDialog="showChangeEmailDialog"
      :userList="userList"
      :currentUser="currentSelectedRow"
      @emailUpdated="emailUpdated"
      @closeAddDialog="showChangeEmailDialog = false"
    />

    <RenameUserNameModal
      :showDialog="showRenamePositionDialog"
      :user="{
        firstName: currentSelectedRow?.user?.first_name || '',
        lastName: currentSelectedRow?.user?.last_name || '',
        accountId: String(currentSelectedRow?.account) || '',
        userId: String(currentSelectedRow?.user?.id) || '',
      }"
      @closeDialog="() => (showRenamePositionDialog = false)"
      @reloadData="getUserList()"
    />
    <CancelInviteAdminUserPopup
      :show="showCancelInviteUserPopup"
      :user="{
        id: currentSelectedRow?.user?.id || 0,
        firstName: currentSelectedRow?.user?.first_name || '',
        lastName: currentSelectedRow?.user?.last_name || '',
      }"
      @onCancelInviteAdminUser="cancelInviteAdminUser"
      @onClose="showCancelInviteUserPopup = false"
    />

    <OwnerShipModal
      :dialog="showOnwerShipDialog"
      @onAction="transferOwner($event)"
      @back="() => (showOnwerShipDialog = false)"
      :items="items"
    />
    <EditPermission
      :showDialog="dialogTitle1"
      :currentUser="currentSelectedRow ? currentSelectedRow : {}"
      :availableItems="appAccessTypes"
      :permissions="appPermissions"
      @closeAddDialog="dialogTitle1 = false"
      @onUpdatePermissions="onSaveAppAccess"
      @onUpdateAppPermissions="updateAppPermissions"
    />
  </v-container>
</template>

<script>
import ExCreateEmployeeWizard from "@components/ExCreateEmployeeWizard.vue";
import SettingsMenu from "@components/SettingsMenu/SettingsMenu.vue";
import DataTable from "@components/DataTable/DataTable.vue";
import ExActionsPopUp from "@components/common/ExActionsPopUp.vue";
import CancelInviteAdminUserPopup from "@components/AdminUserActions/CancelInviteAdminUserPopup.vue";
import {
  getListofUser,
  userResendInvite,
  userStateUpdateSuspend,
  userStateUpdateGiveBack,
  changePassword,
  manageAppAccess,
  transferOwnerShip,
  cancelInviteUser,
} from "@/api";
import UserCard from "@components/common/UserCard.vue";
import UserPermissionBadge from "@components/UserPermissionBadge/UserPermissionBadge.vue";
import UserStatusBadge from "@components/UserStatusBadge/UserStatusBadge.vue";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import CloseIcon from "@components/svgs/CloseIcon.vue";
import MainButton from "@components/buttons/MainButton.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import AppIntegrationsList from "@components/AppIntegrationsList/AppIntegrationsList.vue";
import RenameUserNameModal from "@components/RenameUserModal/RenameUserNameModal.vue";
import ChangeEmailModel from "@components/ChangeEmailModal/ChangeEmailModal.vue";
import { createNamespacedHelpers } from "vuex";
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import clearStateMixin from "@components/views/mixins/clearStateMixin.js";
import ConfigurationView from "@components/SettingView/SettingConfiguration/ConfigurationView.vue";
import OwnerShipModal from "@components/OwnerShipModal/OwnerShipModal.vue";
import EditPermission from "@components/EditPermission/EditPermissionModal.vue";
const { mapActions } = createNamespacedHelpers("isLoading");
import { getAvailableApps } from "@/api";
export default {
  name: "SettingView",
  data() {
    const accessPermission = JSON.parse(
      localStorage.getItem("accessPermission")
    );
    const accessLevel = accessPermission?.is_owner ? "owner" : "co-owner";

    return {
      payRanges: [
        { id: 1, name: "0-10K", code: "A" },
        { id: 2, name: "10K-20K", code: "B" },
        { id: 3, name: "20K-30K", code: "C" },
      ],
      selectedPayRange: null,
      isFlatCard: false,
      storeFilterKey: "organizationSettings",
      selectedTab: 0,
      settingOptions: [
        { title: "Users", count: 0 },
        // commented for v2.1 in ER-3445
        // { title: "Subscription", count: 0 },
        // { title: "Configurations", count: 0 },
        // { title: "Integrations", count: 0 },
        // commented for v2.1 in ER-3445
      ],
      dialog: false,
      email: "",
      accessLevel,
      headers: [
        {
          text: "User",
          sortable: true,
          value: "user",
        },
        {
          text: "Access",
          sortable: false,
          value: "access",
        },
        {
          text: "Activity",
          sortable: false,
          value: "lastlogin",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      accountID: "",
      filterText: "",
      userList: [],
      appAccessTypes: [
        {
          id: 1,
          app: {
            name: "Scale Readiness",
            status: "released",
            field: "has_readiness_access",
            icon: "sidebar_readiness",
          },
          permissionType: "App Permission",
          account: this.accountID,
          appDropdown: "Readiness",
        },
        {
          id: 2,
          app: {
            name: "Scale Knowledge",
            status: "released",
            field: "has_knowledge_access",
            icon: "sidebar_knowledge",
          },
          permissionType: "App Permission",
          account: this.accountID,
          appDropdown: "Knowledge",
        },
        {
          id: 3,
          app: {
            name: "Scale Performance",
            status: "released",
            field: "has_performance_access",
            icon: "sidebar_performance",
          },
          permissionType: "App Permission",
          account: this.accountID,
          appDropdown: "Performance",
        },
        {
          id: 4,
          app: {
            name: "Scale Compensation",
            status: "released",
            field: "has_compensation_access",
            icon: "sidebar_compensation",
          },
          permissionType: "App Permission",
          account: this.accountID,
          appDropdown: "Compensation",
        },
      ],
      paginationModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      currentSelectedRow: {},
      dialogTitle: false,
      dialogTitle1: false,
      appPermissions: [],
      password: "",
      validatePassword: [(v) => !!v || "Password is required"],
      appliedFilters: [],
      clearSelection: "",
      showRenamePositionDialog: false,
      showCancelInviteUserPopup: false,
      showChangeEmailDialog: false,
      isAddDialogVisible: false,
      title: "Users",
      userActions: {
        showResendInvitePopup: false,
        showSuspendPopup: false,
        showGiveBackPopup: false,
      },
      showOnwerShipDialog: false,
      items: [],
    };
  },
  mixins: [dropdownMixin, clearStateMixin],
  created() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.getUserList();
    this.applySavedFilters();
  },
  mounted() {
    this.$loader.show();
    this.getApps();
    setTimeout(() => {
      this.$loader.hide();
    }, 1000);
  },
  computed: {
    appUser() {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      return currentUser;
    },
    userAccountActionDialogObject() {
      if (this.userActions.showResendInvitePopup) {
        const firstName = this.currentSelectedRow?.user?.first_name || "";
        const lastName = this.currentSelectedRow?.user?.last_name || "";
        return {
          title: "Resend Invite",
          description: `You are about to resend invite to ${firstName} ${lastName}`,
          actionText: "Resend",
          primary: true,
          show: true,
          termination: false,
        };
      } else if (this.userActions.showSuspendPopup) {
        const firstName = this.currentSelectedRow?.user?.first_name || "";
        const lastName = this.currentSelectedRow?.user?.last_name || "";
        return {
          title: "Suspend Access",
          description: `You are about to suspend access for ${firstName} ${lastName}`,
          actionText: "Suspend",
          show: true,
          primary: false,
          termination: false,
          destructive: true,
        };
      } else if (this.userActions.showGiveBackPopup) {
        const firstName = this.currentSelectedRow?.user?.first_name || "";
        const lastName = this.currentSelectedRow?.user?.last_name || "";
        return {
          title: "Give back Access",
          description: `You are about to give back access for ${firstName} ${lastName}`,
          actionText: "Give back Access",
          show: true,
          primary: true,
          termination: false,
          destructive: false,
        };
      }
      return {
        title: "",
        description: "",
        actionText: "",
        show: false,
        primary: false,
        termination: false,
      };
    },
    canNext() {
      if (this.password != "") return true;
      return false;
    },
    filteredUsers: function () {
      let resultArray = [];
      resultArray = this.userList.map((el) => {
        const newEl = {
          user: {
            slot: "user",
            data: el,
          },
          access: {
            slot: "access",
          },
          lastlogin: {
            slot: "lastlogin",
          },
          status: {
            slot: "status",
          },
          actions: {
            slot: "actions",
            data: {},
          },
        };
        return newEl;
      });
      if (this.filterText && this.filterText.length > 0) {
        resultArray = resultArray.filter((el) => {
          const firstName = el.user.data.user.first_name.toUpperCase();
          const lastName = el.user.data.user.last_name.toUpperCase();
          const fullName = `${firstName} ${lastName}`;
          const filterTextUpper = this.filterText.toUpperCase();
          return fullName.includes(filterTextUpper);
        });
      }
      if (this.appliedFilters && this.appliedFilters.length > 0) {
        const userFilters = [
          "invited",
          "has access",
          "suspended",
          "blocked",
          "closed",
          "Is Employee",
          "Is Not Employee",
        ];
        if (this.appliedFilters.some((val) => userFilters.includes(val))) {
          resultArray = resultArray.filter((el) => {
            return this.appliedFilters.some((el3) => {
              let val = false;
              if (el3 === "Is Employee") {
                val = el.user.data.has_employee === true;
              } else if (el3 === "Is Not Employee") {
                val = el.user.data.has_employee === false;
              } else if (el3 === "invited") {
                val =
                  el.user.data.user.state === "invited" &&
                  el.user.data.is_suspended === false;
              } else if (el3 === "has access") {
                val =
                  el.user.data.user.state === "operational" &&
                  el.user.data.is_suspended === false;
              } else if (el3 === "suspended") {
                val = el.user.data.is_suspended === true;
              } else if (el3 === "blocked") {
                val = el.user.data.user.state === "blocked";
              } else if (el3 === "closed") {
                val = el.user.data.user.state === "closed";
              }
              return val;
            });
          });
        }
      }

      resultArray.sort((a, b) => {
        const nameA =
          a.user.data.user.first_name.toUpperCase() +
          " " +
          a.user.data.user.last_name.toUpperCase();
        const nameB =
          b.user.data.user.first_name.toUpperCase() +
          " " +
          b.user.data.user.last_name.toUpperCase();
        if (nameA < nameB) {
          return -1; // a comes before b
        } else if (nameA > nameB) {
          return 1; // b comes before a
        } else {
          return 0; // a and b are equal
        }
      });
      return resultArray;
    },
  },
  watch: {
    filteredUsers(newVal) {
      if (newVal) {
        this.paginationModel.total = newVal.length;
      }
    },
  },
  methods: {
    ...mapActions(["setLoadingStatus"]),
    async getApps() {
      const accountId = localStorage.getItem("currentAccountID");
      await getAvailableApps(accountId).then((response) => {
        this.appAccessTypes = [
          {
            id: 1,
            app: {
              name: "Scale Readiness",
              status: "released",
              field: "has_readiness_access",
              icon: "sidebar_readiness",
            },
            permissionType: "App Permission",
            account: this.accountID,
            appDropdown: "Readiness",
            isAppAvailable: response.data.readiness,
          },
          {
            id: 2,
            app: {
              name: "Scale Knowledge",
              status: "released",
              field: "has_knowledge_access",
              icon: "sidebar_knowledge",
            },
            permissionType: "App Permission",
            account: this.accountID,
            appDropdown: "Knowledge",
            isAppAvailable: response.data.knowledge,
          },
          {
            id: 3,
            app: {
              name: "Scale Performance",
              status: "released",
              field: "has_performance_access",
              icon: "sidebar_performance",
            },
            permissionType: "App Permission",
            account: this.accountID,
            appDropdown: "Performance",
            isAppAvailable: response.data.performance,
          },
          {
            id: 4,
            app: {
              name: "Scale Compensation",
              status: "released",
              field: "has_compensation_access",
              icon: "sidebar_compensation",
            },
            permissionType: "App Permission",
            account: this.accountID,
            appDropdown: "Compensation",
            isAppAvailable: response.data.compensation,
          },
        ];
      });
    },
    async emailUpdated() {
      this.formateData();
      this.getUserList();
      this.showChangeEmailDialog = false;
    },
    async cancelInviteAdminUser() {
      this.$loader.setAppLoading(true);
      const userId = this.currentSelectedRow?.user?.id;
      try {
        await cancelInviteUser(userId);
        this.showCancelInviteUserPopup = false;
        this.getUserList();
        this.showCancelInviteUserPopup = false;
      } catch (e) {
        console.error("error: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    getUserAccess(state, isSuspended) {
      if (state == "operational" && isSuspended === false) {
        return "has-access";
      } else if (isSuspended === true) {
        return "suspended";
      } else {
        return state;
      }
    },
    getUserStateLabel(state) {
      return state === "invited" ? "Invited on " : "Last seen on ";
    },
    OnActionUserConfirmationDialog() {
      if (this.userActions.showResendInvitePopup) {
        this.resendInviteAction();
      } else if (this.userActions.showSuspendPopup) {
        this.suspendAccessAction();
      } else if (this.userActions.showGiveBackPopup) {
        this.giveBackAccessAction();
      }
    },
    OnCloseUserConfirmationDialog() {
      if (this.userActions.showResendInvitePopup) {
        this.userActions.showResendInvitePopup = false;
      } else if (this.userActions.showSuspendPopup) {
        this.userActions.showSuspendPopup = false;
      } else if (this.userActions.showGiveBackPopup) {
        this.userActions.showGiveBackPopup = false;
      }
    },
    formatDate(date) {
      const originalDate = new Date(date);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[originalDate.getMonth()];
      const year = originalDate.getFullYear();
      const day = originalDate.getDate();

      // Pad hours and minutes with leading zeros
      const hours = String(originalDate.getHours()).padStart(2, "0");
      const minutes = String(originalDate.getMinutes()).padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },
    async onCreatedUser(user) {
      console.log(user);
      await this.loadUsers();
    },
    onUpdateShadow(val) {
      this.isFlatCard = val;
    },
    handleTabChange(tabIndex) {
      this.isFlatCard = false;
      this.selectedTab = tabIndex;
    },
    applySavedFilters() {
      this.$store.dispatch("filterStore/loadFilters", this.storeFilterKey);
      const savedFilters = this.$store.state.filterStore.filters;
      if (savedFilters && savedFilters.length > 0) {
        this.appliedFilters = savedFilters;
      }
    },
    resendInviteAction() {
      this.setLoadingStatus(true);
      if (this.currentSelectedRow?.user?.id) {
        const obj = {
          user: this.currentSelectedRow?.user.id,
          account: this.accountID ?? this.currentSelectedRow.account,
        };
        userResendInvite(this.currentSelectedRow.user.id, obj)
          .then(() => {
            //
          })
          .catch((err) => {
            console.error("error", err);
          })
          .finally(() => {
            this.setLoadingStatus(false);
            this.userActions.showResendInvitePopup = false;
          });
      } else {
        this.setLoadingStatus(false);
        this.userActions.showResendInvitePopup = false;
      }
    },
    suspendAccessAction() {
      this.setLoadingStatus(true);
      if (this.currentSelectedRow?.user?.id) {
        let obj = {
          email: this.currentSelectedRow.user.email,
          state: "suspended",
        };
        userStateUpdateSuspend(this.accountID, this.currentSelectedRow.id, obj)
          .then(() => {
            this.currentSelectedRow.user.state = "suspended";
            this.formateData();
            this.getUserList();
          })
          .catch((err) => {
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.setLoadingStatus(false);
            this.userActions.showSuspendPopup = false;
          });
      } else {
        this.setLoadingStatus(false);
        this.userActions.showSuspendPopup = false;
      }
    },
    giveBackAccessAction() {
      this.setLoadingStatus(true);
      if (this.currentSelectedRow?.user?.id) {
        let obj = {
          email: this.currentSelectedRow.user.email,
          state: "invited",
        };
        userStateUpdateGiveBack(this.accountID, this.currentSelectedRow.id, obj)
          .then(() => {
            this.currentSelectedRow.user.state = "invited";
            this.formateData();
            this.getUserList();
          })
          .catch((err) => {
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.setLoadingStatus(false);
            this.userActions.showGiveBackPopup = false;
          });
      } else {
        this.setLoadingStatus(false);
        this.userActions.showGiveBackPopup = false;
      }
    },
    getUserList() {
      this.setLoadingStatus(true);
      getListofUser(this.accountID)
        .then((res) => {
          this.userList = res.data.results;
          this.formateData();
          if (res.data && res.data.count) {
            this.paginationModel.total = res.data.count;
          }
        })
        .catch((error) => {
          this.userList = [];
          console.log("error: ", error);
        })
        .finally(() => {
          this.setLoadingStatus(false);
        });
    },
    async requestData(data) {
      if (data && data.page) {
        this.paginationModel.page = data.page;
      }
    },
    async loadUsers(pageNo = 1) {
      const { data } = await getListofUser(this.accountID, pageNo, 10);
      if (data && data.count) {
        this.paginationModel.total = data.count;
      }
      if (data && data.results) {
        this.userList = data.results;
        this.formateData();
      }
    },
    formateData() {
      this.userList.forEach((element) => {
        element.user.full_name =
          element.user.first_name + " " + element.user.last_name;
        element.accessChip = [];
        if (element.has_access) {
          if (element.is_owner) {
            element.accessChip.push({
              permissionType: "Owner",
              appDropdown: "Owner",
              IsEnabled: true,
            });
          } else if (element.is_co_owner) {
            element.accessChip.push({
              permissionType: "Co-Owner",
              appDropdown: "Co-Owner",
              IsEnabled: true,
            });
          } else {
            this.appAccessTypes.forEach((access) => {
              if (element[access.app.field]) {
                element.accessChip.push(access);
              }
            });
          }
        }
        element.actionMenu = [];
        if (element.user.state == "invited") {
          element.actionMenu.push({
            name: "Resend Invite",
            value: "resend_invite",
            icon: "icons/dropdown/resend.svg",
            order: 1,
            disabled: false,
          });
        } else if (element.user.state == "draft") {
          element.actionMenu.push({
            name: "Activate",
            value: "activate",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        } else if (element.user.state == "active") {
          element.actionMenu.push({
            name: "Suspend",
            value: "suspend",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        } else if (element.user.state == "suspended") {
          element.actionMenu.push({
            name: "Re-Activate",
            value: "reactivate",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        }
        element.actionMenu.push({
          name: "Change Password",
          value: "change_password",
          icon: "edit_employee.svg",
          order: 1,
          disabled: false,
        });
        if (
          !element.is_owner &&
          !element.is_co_owner &&
          !element.is_suspended
        ) {
          element.actionMenu.push({
            name: "Manage Apps Access",
            value: "manage_apps",
            icon: "edit_employee.svg",
            order: 1,
            disabled: false,
          });
        }
        if (!element.is_owner && !element.is_suspended) {
          element.actionMenu.push({
            name: "Suspend",
            value: "suspend",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
            negative: true,
          });
        }
        if (!element.is_owner && element.is_suspended) {
          element.actionMenu.push({
            name: "Give back access",
            value: "give_back_access",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        }
      });
    },
    handleDropdownClick(data, row) {
      console.log("🚀 ~ handleDropdownClick ~ data:", data);
      this.currentSelectedRow = this.userList.find(
        (x) => x.id === row.user.data.id
      );
      if (data.value == "resend_invite") {
        this.userActions.showResendInvitePopup = true;
      } else if (data.value == "suspend") {
        this.userActions.showSuspendPopup = true;
      } else if (data.value == "give_back_access") {
        this.userActions.showGiveBackPopup = true;
      } else if (data.value == "change_permissions_action") {
        this.appPermissions = [];
        this.dialogTitle1 = true;
        this.dialogTitle = false;
        this.appAccessTypes.forEach((access) => {
          if (row.user.data[access.app.field]) {
            this.appPermissions.push(access.id);
          }
        });
      } else if (data.value == "rename_action") {
        this.showRenamePositionDialog = true;
        this.dialogTitle = false;
      } else if (data.value == "change_email_action") {
        this.showChangeEmailDialog = true;
        this.dialogTitle = false;
      } else if (data.value == "transfer_ownership_action") {
        this.showOnwerShipDialog = true;
        this.dialogTitle = false;
        this.items = [];
        this.userList.forEach((element) => {
          if (
            element.user.state !== "closed" &&
            element.user.state !== "blocked"
          ) {
            if (element.is_owner === false && element.is_suspended === false) {
              this.items.push({
                id: element.user.id,
                fullName: element.user.full_name,
                email: element.user.email,
              });
            }
          }
        });
      } else if (data.value === "cancel_invite_action") {
        this.showCancelInviteUserPopup = true;
      }
    },
    transferOwner(event) {
      this.setLoadingStatus(true);
      const obj = {
        email: event.user.email,
      };
      transferOwnerShip(this.accountID, obj)
        .then(() => {
          this.loadUsers();
        })
        .catch((err) => {
          console.error("error", err);
        })
        .finally(() => {
          this.setLoadingStatus(false);
          this.showOnwerShipDialog = false;
        });
    },
    onChangePassword() {
      if (this.$refs.password.validate()) {
        this.setLoadingStatus(true);
        changePassword(this.currentSelectedRow.user.id, {
          password: this.password,
        })
          .then(() => {
            // this.$refs.password.reset();
          })
          .catch((err) => {
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.$refs.password.reset();
            this.dialogTitle = false;
            this.setLoadingStatus(false);
          });
      }
    },
    updateAppPermissions(val) {
      this.appPermissions = val;
    },
    onSaveAppAccess() {
      this.$loader.setAppLoading(true);
      const obj = {};
      this.appAccessTypes.forEach((item) => {
        obj[item.app.field] = this.appPermissions.includes(item.id);
      });
      manageAppAccess(this.accountID, this.currentSelectedRow.user.id, obj)
        .then(() => {
          this.appAccessTypes.forEach((access) => {
            this.currentSelectedRow[access.app.field] =
              this.appPermissions.includes(access.id);
          });
          this.formateData();
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$toast.error("something went wrong, please try again.");
        })
        .finally(() => {
          this.dialogTitle1 = false;
          this.$loader.setAppLoading(false);
        });
    },
    onRemoveAppAccess(access, row) {
      this.currentSelectedRow = this.userList.find(
        (x) => x.id === row.user.data.id
      );
      this.setLoadingStatus(true);
      const obj = {};
      switch (access) {
        case "Proficiency":
          obj.has_readiness_access =
            this.appPermissions.includes("Proficiency");
          break;
        case "Knowledge":
          obj.has_knowledge_access = this.appPermissions.includes("Knowledge");
          break;
        case "Performance":
          obj.has_performance_access =
            this.appPermissions.includes("Performance");
          break;
        case "Compensation":
          obj.has_compensation_access =
            this.appPermissions.includes("Compensation");
          break;
        case "Employee Portal":
          obj.has_employee_portal_access =
            this.appPermissions.includes("Employee Portal");
          break;
        default:
          break;
      }
      manageAppAccess(this.accountID, this.currentSelectedRow.user.id, obj)
        .then(() => {
          this.appAccessTypes.forEach((access) => {
            this.currentSelectedRow[access.app.field] =
              this.appPermissions.includes(access.id);
          });
          this.loadUsers();
          this.formateData();
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$toast.error("something went wrong, please try again.");
        })
        .finally(() => {
          this.dialogTitle1 = false;
          this.setLoadingStatus(false);
        });
    },
    reset() {
      this.dialogTitle = false;
      this.dialogTitle1 = false;
      this.accountPermission = {
        is_co_owner: true,
        has_access: true,
        has_readiness_access: false,
        has_knowledge_access: false,
        has_performance_access: false,
        has_compensation_access: false,
        has_employee_portal_access: true,
      };
    },
    onFilter(data) {
      this.filterText = data;
    },
    removeFilter(removedFilter) {
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.clearSelection = removedFilter;
    },
    filteredModalUsers(filters) {
      const newArray = filters
        .map((item) => {
          if (item.value !== null) {
            if (typeof item === "string") {
              return item;
            } else if (
              typeof item === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              item.hasOwnProperty("value")
            ) {
              return item.value;
            }
          }
        })
        .filter(Boolean);
      this.appliedFilters = newArray;
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
    },
    setUserStatus(user) {
      if (user.state == "") {
        return "has-access";
      } else {
        return "has-access";
      }
    },
    clearSearchFilter() {
      if (this.appliedFilters?.length > 0) {
        this.appliedFilters = [];
        this.filteredModalUsers(this.appliedFilters);
      }
      if (this.filterText?.length > 0) {
        this.filterText = "";
      }
    },
    toggleAppSelect(appId) {
      if (this.appPermissions.includes(appId)) {
        this.appPermissions = [
          ...this.appPermissions.filter(
            (appInstallId) => appInstallId != appId
          ),
        ];
      } else {
        this.appPermissions = [...this.selectedApps, appId];
      }
    },
    onPayRangeSelected(selectedItem) {
      this.selectedPayRange = selectedItem;
      console.log("Selected Pay Range:", selectedItem);
    },
    onPayRangeCleared() {
      this.selectedPayRange = null;
      console.log("Pay range selection cleared");
    },
  },
  components: {
    CancelInviteAdminUserPopup,
    EditPermission,
    ExCreateEmployeeWizard,
    SettingsMenu,
    DataTable,
    UserCard,
    UserPermissionBadge,
    UserStatusBadge,
    DropDownMenu,
    CloseIcon,
    MainButton,
    ExActionsPopUp,
    AppIntegrationsList,
    RenameUserNameModal,
    NoRecordFound,
    ConfigurationView,
    OwnerShipModal,
    ChangeEmailModel,
  },
};
</script>
<style scoped>
.with-employee {
  width: 100%;
}
</style>
<style scoped>
.button-next {
  width: 87px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: solid 1px rgba(234, 236, 240, 1);
  background-color: rgba(242, 244, 247, 1);
  color: rgba(152, 162, 179, 1);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.text-black-600 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}

.text-black-500 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}
.v-text-field__slot {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(102, 112, 133, 1);
}
.input {
  border-radius: 8px;
  margin: 0;
}

.pop-up {
  width: 480px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
}
.modal_header {
  z-index: 1;
  position: relative;
}
.modal_content {
  z-index: 1;
  position: relative;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
.font-size-14 {
  font-size: 14px;
}
.text-disabled {
  color: rgba(102, 112, 133, 1);
}

.text-gray-dark {
  color: #666666;
}
.app-selector {
  border: 2px solid rgba(127, 86, 217, 1);
  cursor: pointer;
}
.v-input--selection-controls {
  padding-top: 0;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin: 0px;
}
.v-input--selection-controls__input .v-input__slot .v-label {
  padding-left: 1px !important;
}
.v-input--selection-controls__input .v-input__slot .theme--light .v-label {
  color: black;
  font-size: 14px;
}
</style>

<style>
.pop-up .v-input__slot {
  min-height: 44px !important;
}
.status-label {
  margin-left: 10px;
}
.storybook-status-badge--suspended .custom-bedge.v-badge--dot .v-badge__badge {
  background-color: #b42318 !important;
}
.owner-badge-list {
  height: 60px !important;
}

.elevated-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
}

.st-setting
  .container--fluid
  .v-item-group
  .v-window-item
  .v-sheet.v-card:not(.v-sheet--outlined) {
  margin: 20px;
}

.st-setting .horizontal-tab {
  width: 96%;
  margin-left: 20px;
}
.st-setting h1 {
  margin-left: 20px;
}
</style>
