import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListofEmployee = async (account_id, page_no, size) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/employee/`,
    params: { page: page_no, size: size },
  });
  return result;
};

export const createEmployee = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/`,
    data: data,
  });
  return result;
};

export const activateEmployee = (account_id, employee_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${employee_id}/activate/`,
  });
  return result;
};

export const updateEmployee = (account_id, employee_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/employee/${employee_id}/`,
    data: data,
  });
  return result;
};

export const employeeStateUpdate = (account_id, user_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/employee/${user_id}/`,
    data: data,
  });
  return result;
};

export const terminateEmployee = (account_id, user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${user_id}/terminate/`,
  });
  return result;
};

export const reactivateEmployee = (account_id, employee_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${employee_id}/reactivate/`,
  });
  return result;
};

export const deleteEmployee = (account_id, user_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/employee/${user_id}/`,
  });
  return result;
};

export const getEmployeeById = (account_id, emp_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/employee/${emp_id}/`,
  });
  return result;
};

export const getEmployeeChatter = (account_id, emp_id, page = 1) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/employee/${emp_id}/chatter/`,
    params: { page: page },
  });
  return result;
};
export const getEmployeeAssignment = (account_id, emp_id, val) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/employee/${emp_id}/assignments/`,
    params: { path_to_readiness: val },
  });
  return result;
};

export const getEmployeeBadges = (account_id, emp_id) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/employee/${emp_id}/badge/`,
  });
  return result;
};

export const removePositionAssignmentForEmployee = (
  account_id,
  assignment_id
) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position-assignment/${assignment_id}/relieve/`,
  });
  return result;
};
export const archiveEmployee = (account_id, empId) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${empId}/archive/`,
  });
  return result;
};
export const unArchiveEmployee = (account_id, empId) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${empId}/unarchive/`,
  });
  return result;
};
export const recalculateEmployeeReadiness = (account_id, emp_id) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/employee/${emp_id}/recalculate-readiness/`,
  });
  return result;
};

export const updateImportanceValue = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/assignment/change-importance/`,
    data: data,
  });
  return result;
};
export const updateEmployeeReadiness = (account_id, employee_id) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/employee/${employee_id}/recalculate-readiness/`,
  });
  return result;
};
export const updateAllEmployeeReadiness = (account_id, employee_ids) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/recalculate-all-readiness/`,
    data: employee_ids,
  });
  return result;
};
