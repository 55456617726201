var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"select-picker"},[_c('div',{staticClass:"text-medium-emphasis label-name",on:{"click":_vm.handleLabelClick}},[_vm._v(" "+_vm._s(_vm.pickerLabel)+"* ")]),_c('v-text-field',{ref:"pickerFieldRef",staticClass:"custom-response-picker",class:[
      _vm.selectedItemFullName && _vm.hasImageItem
        ? 'auto-picker'
        : 'custom-response-picker',
      _vm.clearAbleIcon ? 'sh-clear-icon' : 'sh-not-clear-icon',
      _vm.selectedItemFullName && _vm.isReadonly === false ? 'picker-searching' : '',
      _vm.isInputFocused && !_vm.selectedItemObject ? 'add-margin-left' : '',
      _vm.hasImageItem && _vm.selectedItemObject
        ? 'add-margin-left'
        : 'no-margin-left',
    ],attrs:{"outlined":"","placeholder":_vm.selectedEmployee ? '' : _vm.placeholder,"hide-details":true,"readonly":_vm.isforceReadonly ? true : _vm.isReadonly,"disabled":_vm.disabled},on:{"input":_vm.searchItems,"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"keydown":_vm.onKeyDown},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.clearAbleIcon)?_c('v-icon',{staticClass:"append-icon-hover",on:{"mouseenter":_vm.onIconHover,"mouseleave":_vm.onIconLeave,"click":_vm.resetSelectedItem}},[_vm._v(" "+_vm._s(_vm.clearIcon)+" ")]):_c('v-icon',{staticClass:"append-icon-hover",on:{"mouseenter":_vm.onIconHover,"mouseleave":_vm.onIconLeave,"click":function($event){return _vm.onAppendIconClick()}}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")])]},proxy:true},{key:"prepend-inner",fn:function(){return [(_vm.isEmployeePicker && !_vm.isInputFocused && !_vm.selectedItemObject)?_c('Icon',{staticClass:"employee-search-icon",attrs:{"name":"employee_search"}}):_vm._e(),(_vm.isInputFocused && !_vm.selectedItemObject)?_c('img',{staticClass:"prepend-icon-inner-custom-search",attrs:{"src":require('@components/assets/search-lg.png'),"alt":"icon"}}):_vm._e(),(_vm.selectedItemObject && _vm.hasIcon(_vm.selectedItemObject?.icon))?_c('Avatar',{staticClass:"selected-currency-icon prepend-icon-inner-custom",attrs:{"picture":_vm.hasIcon(_vm.selectedItemObject.icon),"size":"small"}}):_vm._e(),(_vm.selectedItemObject && _vm.isEmployeePicker)?_c('Avatar',{staticClass:"selected-currency-icon prepend-icon-inner-custom",attrs:{"firstName":_vm.selectedItemObject.firstName,"lastName":_vm.selectedItemObject.lastName,"picture":_vm.isAvatar(_vm.selectedItemObject.avatar),"size":"small"}}):_vm._e()]},proxy:true},{key:"label",fn:function(){return [(_vm.isInputFocused)?_c('label',{staticClass:"response-label-class",class:{
          'custom-label-class': !_vm.isInputFocused && _vm.selectedItemFullName,
        }},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e(),(!_vm.isInputFocused && !_vm.selectedEmployee && !_vm.selectedItemFullName)?_c('label',{staticClass:"response-label-class"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedItemFullName),callback:function ($$v) {_vm.selectedItemFullName=$$v},expression:"selectedItemFullName"}}),(_vm.isListVisible)?_c('div',{staticClass:"list-picker-container"},[(_vm.isListVisible)?_c('v-list',{staticClass:"res-custom-list-picker",class:{
        'res-custom-list-picker-change': _vm.isInputFocused,
      }},[_vm._l((_vm.filteredData),function(item,index){return _c('v-list-item',{key:`item-${index}-${item.id}`,staticClass:"res-v-list-item",class:{
          'highlighted-item': _vm.isSelected(item),
        },on:{"click":function($event){$event.stopPropagation();return _vm.onListItemClick(item)}}},[(_vm.isEmployeePicker)?_c('Avatar',{staticClass:"list-currency-icon",attrs:{"firstName":item.firstName,"lastName":item.lastName,"picture":_vm.isAvatar(item.avatar),"size":"small"}}):_vm._e(),(_vm.hasIcon(item.icon))?_c('Avatar',{staticClass:"list-currency-icon",attrs:{"size":"small","picture":_vm.hasIcon(item.icon)}}):_vm._e(),_c('v-list-item-content',{staticClass:"selector-list-items"},[_c('v-list-item-title',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),(_vm.useCode && item.hasOwnProperty('code'))?_c('div',{staticClass:"item-code"},[_vm._v("   ("+_vm._s(item.code)+") ")]):_vm._e()]),(_vm.isSelected(item))?_c('img',{staticClass:"currency-right-icon",attrs:{"src":require("@components/assets/rightIcon.svg"),"alt":"icon"}}):_vm._e()])],1)],1)}),(!_vm.filteredData.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"no-text"},[_vm._v("Nothing matching your search")])],1)],1):_vm._e()],2):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }