import { reactive } from "vue";
import { useFilters } from "@/composables/filters/useFilters";

let instance = null;

export function useViewState() {
  if (instance) return instance;
  const filter = useFilters();
  const state = reactive({
    filters: {
      values: [],
      searchText: "",
      showFilterDialog: false,
      key: "readinessEmployees",
      clearSelection: "",
      needsRecalculation: false,
    },
    loading: {
      table: false,
    },
    table: {
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
  });

  const applyFilters = (filters) => {
    const newArray = filters
      .map((item) => {
        if (item.value !== null) {
          if (typeof item === "string") {
            return item;
          } else if (
            typeof item === "object" &&
            // eslint-disable-next-line no-prototype-builtins
            item.hasOwnProperty("value")
          ) {
            return item.value;
          }
        }
      })
      .filter(Boolean);
    filter.setFilters({ key: state.filters.key, filters: newArray });
    state.filters.values = newArray;
    state.filters.showFilterDialog = false;
  };

  const removeFilters = (removedFilters) => {
    filter.setFilters({
      key: state.filters.key,
      filters: state.filters.values,
    });
    state.filters.clearSelection = removedFilters;
  };

  const getOrgObjectCardProps = (obj) => {
    return {
      code: obj.code,
      name: obj.name,
      showIcon: false,
      removeUnderlineToName: true,
    };
  };
  const getSwitcherProps = (obj) => {
    return {
      label: "",
      value: obj.isCoach,
    };
  };
  const getCheckPointCounterProps = (obj) => {
    return {
      countApprentice: obj.checkpoint.apprentice,
      countProfessional: obj.checkpoint.professional,
      countCoach: obj.checkpoint.coach,
      countMaster: obj.checkpoint.master,
    };
  };

  // Métodos para filtros
  const updateFilter = (filterName, value) => {
    if (filterName in state.filters) {
      state.filters[filterName] = value;
    }
  };

  const clearFilters = () => {
    state.filters = {
      status: "all",
      department: null,
      position: null,
    };
  };

  // Métodos para loading
  const setLoading = (key, value) => {
    if (key in state.loading) {
      state.loading[key] = value;
    }
  };

  instance = {
    state,
    updateFilter,
    clearFilters,
    setLoading,
    getOrgObjectCardProps,
    getSwitcherProps,
    getCheckPointCounterProps,
    applyFilters,
    removeFilters,
  };
  return instance;
}
